<template>
  <div class="p-visitorTop">
    <img class="p-visitorTop_logo" src="@/assets/img/logo.svg" />

    <p class="p-visitorTop_txt">
      ご来訪ありがとうございます。 <br />
      以下のボタンより手続きをお願いします。
    </p>

    <div class="p-visitorTop_btnLarge">
      <BtnLarge txt="入居者とアポイントがある方" link="/visitor_company_list" />
    </div>

    <div class="p-visitorTop_btnSmall">
      <!-- <BtnSmall txt="郵送や配達の方" link="/visitor_company_post" /> -->
      <BtnSmall txt="受付にご用の方" link="/call_jeki" />
    </div>
  </div>
</template>

<script>
import BtnLarge from "@/components/BtnLarge.vue";
import BtnSmall from "@/components/BtnSmall.vue";

export default {
  name: "VisitorTop",
  components: {
    BtnLarge,
    BtnSmall,
  },
};
</script>

<style scoped lang="scss">
.p-visitorTop {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px auto 0;
  width: 416px;

  &_logo {
    width: 291px;
    height: 97px;
  }

  &_txt {
    margin-top: 40px;
    text-align: center;
  }

  &_btnLarge {
    margin-top: 64px;
  }

  &_btnSmall {
    display: flex;
    justify-content: center;
    margin-top: 60px;
    width: 100%;
  }
}
</style>
