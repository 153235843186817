<template>
  <router-link :to="`${link}`" class="c-btnLarge">
    <p class="c-btnLarge_txt">{{ txt }}</p>
    <img class="c-btnLarge_arrow" src="@/assets/img/arrow-white.svg" />
  </router-link>
</template>

<script>
export default {
  name: "BtnLarge",
  props: {
    txt: String,
    link: String,
  },
};
</script>

<style scoped lang="scss">
.c-btnLarge {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 2px #0e0000;
  border-radius: 11px;
  width: 416px;
  height: 78px;
  background-color: $black;

  &_txt {
    padding-right: 18px;
    color: $white;
    font-size: 20px;
    font-weight: 700;
  }

  &_arrow {
    position: absolute;
    top: auto;
    right: 24px;
    bottom: auto;
    width: 31px;
    height: 15px;
  }
}
</style>
