<template>
  <router-link :to="`${link}`" class="c-btnSmall">
    <p class="c-btnSmall_txt">{{ txt }}</p>
    <img class="c-btnSmall_arrow" src="@/assets/img/arrow-black.svg" />
  </router-link>
</template>

<script>
export default {
  name: "BtnSmall",
  props: {
    txt: String,
    link: String,
  },
};
</script>

<style scoped lang="scss">
.c-btnSmall {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px $black;
  border-radius: 11px;
  width: 187px;
  height: 60px;
  background-color: $white;

  &_txt {
    padding-right: 37px;
    color: $black;
    font-size: 14px;
    font-weight: 400;
  }

  &_arrow {
    position: absolute;
    top: auto;
    right: 16px;
    bottom: auto;
    width: 31px;
    height: 15px;
  }
}
</style>
